import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { FC, useContext } from "react";
import { CrossBulletIcon } from "../icons/CrossBulletIcon";
import { useTranslations } from "../../shared/i18n/localization";
import { TrackingConsentContext } from "./TrackingConsentManager";
import {
  CookieDefinition,
  TrackingConsentState,
} from "./TrackingConsentManagerInterface";

const KeyValueItem: React.FC<{ _key: string; value: string }> = ({
  _key,
  value,
}) => (
  <Box>
    <Text color="primary.500" textStyle="p1">
      {_key}
    </Text>
    <Text textStyle="p1">{value}</Text>
  </Box>
);

const CookieInfo: React.FC<{ cookie: CookieDefinition }> = ({ cookie }) => {
  const { translate } = useTranslations();
  return (
    <VStack alignItems="stretch">
      <KeyValueItem
        _key={translate("cookiebanner.cookie_info.purpose")}
        value={translate(`cookiebanner.cookies.purpose.${cookie.purpose}`)}
      />
      <KeyValueItem
        _key={translate("cookiebanner.cookie_info.provider")}
        value={translate(`cookiebanner.cookies.provider.${cookie.provider}`)}
      />
      <KeyValueItem
        _key={translate("cookiebanner.cookie_info.lifespan")}
        value={translate(`cookiebanner.cookies.lifespan.${cookie.lifespan}`)}
      />
      <KeyValueItem
        _key={translate("cookiebanner.cookie_info.name")}
        value={cookie.actualName}
      />
    </VStack>
  );
};

const CookiesAccordeon: React.FC<{ cookies: CookieDefinition[] }> = ({
  cookies,
}) => {
  const { translate } = useTranslations();
  return (
    <Accordion allowToggle padding={0} marginTop={2}>
      {cookies.map((cookie, i) => (
        <AccordionItem
          _last={{ borderEnd: "" }}
          justifyContent="space-between"
          key={i}
        >
          <AccordionButton
            _expanded={{
              bg: "background.light-ink",
              fontWeight: 600,
            }}
            _hover={{
              bg: "background.light-ink",
            }}
            _focus={{
              border: "none",
            }}
            paddingY={4}
            paddingX={5}
          >
            <Box flex="1" textAlign="left" textColor="primary.500">
              {translate(
                `cookiebanner.cookies.friendly_name.${cookie.friendlyName}`
              )}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel margin={0} paddingX={4} marginTop={2}>
            <CookieInfo cookie={cookie} />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

const CookieCategoryAccordeon = () => {
  const { translate } = useTranslations();
  const consentManager = useContext(TrackingConsentContext);
  const allCookieDefinitions = Object.values(
    consentManager.consentConfigurations
  )
    .map((i) => i.cookies)
    .flat();

  const categories = [
    {
      name: translate("cookiebanner.functional_cookies.category_name"),
      introText: translate("cookiebanner.functional_cookies.intro_text"),
      cookies: consentManager.functionalCookies,
    },
    {
      name: translate("cookiebanner.analytics_cookies.category_name"),
      introText: translate("cookiebanner.analytics_cookies.intro_text"),
      cookies: allCookieDefinitions,
    },
  ];

  return (
    <Accordion allowMultiple padding={0} marginTop={2}>
      {categories
        .filter((category) => category.cookies.length > 0)
        .map((category) => (
          <AccordionItem
            key={category.name}
            justifyContent="space-between"
            _first={{ border: "none" }}
          >
            <AccordionButton
              _expanded={{
                bg: "background.light-ink",
                fontWeight: 600,
              }}
              _hover={{
                bg: "background.light-ink",
              }}
              _focus={{
                border: "none",
              }}
              paddingY={4}
              paddingX={5}
            >
              <Text flex="1" textAlign="left" textColor="primary.500">
                {category.name}
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel padding={0} marginY={2.5}>
              <Stack
                alignItems="stretch"
                borderColor="background.light-ink"
                borderRadius="6px"
                borderWidth="3px"
                _hover={{ cursor: "pointer" }}
                bg={undefined}
              >
                <Heading as="h3" variant="h3" padding={4} paddingBottom={1}>
                  {category.introText}
                </Heading>
                <CookiesAccordeon cookies={category.cookies} />
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        ))}
    </Accordion>
  );
};

interface CookieBannerInnerProps {
  onAccept: (scopes: Set<string>) => void;
}

const CookieBannerInner: React.FC<CookieBannerInnerProps> = (props) => {
  const { translate } = useTranslations();
  const consentManager = useContext(TrackingConsentContext);
  const allConsentSet = new Set(
    Object.keys(consentManager.consentConfigurations)
  );

  return (
    <Box
      position="fixed"
      left={0}
      top={0}
      height="100%"
      width="100%"
      zIndex={2000}
      background="#1A0F0F4D"
      data-nosnippet
    >
      <Box
        maxWidth={{ base: "374px", xl: "450px" }}
        padding={{ base: "21px", xl: "28px" }}
        margin={4}
        marginTop={{ base: "76px", xl: "86px" }}
        borderRadius={{ base: "4px 30px 30px 30px", xl: "6px 35px 35px 35px" }}
        boxShadow="3px 4px 4px rgba(51, 51, 51, 0.1)"
        backgroundColor="monochrome.white"
        alignItems="stretch"
      >
        <Box>
          <CrossBulletIcon boxSize={{ base: 4, xl: "18px" }} />
          <Heading variant="h2" paddingBottom="18px">
            {translate("cookiebanner.heading")}
          </Heading>
        </Box>
        <Box overflow="auto" maxHeight="47vh">
          <Text>{translate("cookiebanner.intro_text")}</Text>
          <CookieCategoryAccordeon />
        </Box>
        <HStack
          justify={"space-between"}
          marginTop={5}
          wrap="wrap"
          flexDirection="row-reverse"
        >
          <Button onClick={() => props.onAccept(allConsentSet)} flexGrow={1}>
            {translate("cookiebanner.buttons.accept_all")}
          </Button>
          <Button
            variant="tertiary"
            aria-label="info"
            title="info"
            onClick={() => props.onAccept(new Set())}
            flexGrow={1}
            paddingY="11px"
            paddingX="5px"
          >
            {translate("cookiebanner.buttons.accept_functionals")}
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};

export const CookieBanner: FC = () => {
  const consentManager = useContext(TrackingConsentContext);
  const visible =
    consentManager.isInitialized &&
    consentManager.trackingConsent.state == TrackingConsentState.consentUnknown;

  return (
    <>
      {visible && (
        <CookieBannerInner onAccept={consentManager.onUserUpdatedConsent} />
      )}
    </>
  );
};
